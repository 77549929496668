* {
    /* width */
    &::-webkit-scrollbar {
        width: 8px;
        height: 10%;
    }

    /* Track */
    &::-webkit-scrollbar-track {
        background-color: rgba(#fff, 0.329);
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
        background: rgba($color-blue, 1);
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
        background: rgba($color-blue-darker, 1);
    }
}