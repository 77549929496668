.navbar {
    background-color: $color-dark;
    padding-top: 0;
    padding-bottom: 0;
    padding: 0;
    height: 70px;
    position: absolute;
    width: 100%;
    z-index: 99;

    &-brand {
        img {
            max-width: 130px;
        }

        @include mq('desktop', max) {
            position: relative;
            z-index: 1000;
        }
    }


    &-nav {
        @include mq('desktop', max) {
            transition: all .5s ease;
            opacity: 0;
            visibility: hidden;
            display: flex !important;
            flex-direction: column !important;
            justify-content: center;
            align-items: center;
            position: absolute;
            width: 100%;
            min-height: 100vh;
            background-color: $color-dark;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            z-index: 999;
        }

        li {
            margin-left: 7px;
            margin-right: 7px;

            a {
                padding-left: 0;
                padding-right: 0;
                font-size: 13px;
                font-family: "OpenSans-SemiBold", Arial, Helvetica, sans-serif;
                color: white;
                text-transform: uppercase;

                &.active,
                &:hover {
                    color: $color-orange;
                }

                @include mq('desktop', max) {
                    font-size: 18px;
                }

                @include mq('phone-small', max) {
                    font-size: 16px;
                }
            }

           
        }
    }

    .container {
        padding: 0 15px;
    }
}

.navIsOpen {
    opacity: 1;
    visibility: visible;
}