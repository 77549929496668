.section {
    &__title {
        position: absolute;
        z-index: 1;
        font-weight: 700;
        white-space: nowrap;
        text-transform: uppercase;
        color: rgba(255, 255, 255, 0.02);

        left: 0vw;
        top: 4vw;
        font-size: 14vw;
        line-height: 11vw;
        letter-spacing: -0.3vw;

        @include mq('desktop', max) {
            left: -10.5vmin;
            font-size: 26.5vmin;
            line-height: 20.8vmin;
            letter-spacing: -0.5vmin;
        }
    }

    &__content {
        height: 100vh;
        width: 100%;
        position: relative;
        z-index: 99;
        
        &--slider {
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
            flex-direction: column;
            padding: 0 75px;
        }


        h1 {
            font-size: 36px;
            font-family: "OpenSans-Bold";
            color: rgb(255, 255, 255);
            text-transform: uppercase;
            line-height: 1.167;

            @include mq('tablet-wide', max) {
                font-size: 24px;
            }

            @include mq('phone-small', max) {
                font-size: 18px;
            }
        }

        p {
            font-size: 18px;
            font-family: "OpenSans-Regular";
            color: rgba(255, 255, 255, 0.8);
            line-height: 1.444;

            @include mq('tablet-wide', max) {
                font-size: 16px;
            }

            @include mq('phone', max) {
                font-size: 14px;

                @include text-clamp(3, 60px);
            }
        }

        @include mq('tablet-wide', max) {
            padding: 0;

        }
    }
}

.all-portfolio {
    right: 200px;
    color: $color-orange;
    text-decoration: underline;
    top: 50%;
    transform: translateY(-50%);

    font-size: 24px;
    position: absolute;
    &:hover {
        color: $color-orange;
    }
    @include mq('tablet', max) {
        position: unset;
        transform: unset;
        font-size: 16px;
        display: flex;
        justify-content: center;
        margin: -5px 0 -15px;
    }
}