.modal {

    &-search {
        top: 0;
        overflow: unset !important;
        padding-right: 0 !important;

        .modal {
            &-dialog {
                height: 70px;
                margin: 0;
                overflow: unset;
                min-width: 100%;
            }

            &-content {
                border: 0;
                border-radius: 0;
                background-color: $color-dark;
            }

            &-header {
                height: 70px;
                border: 0;
                border-radius: 0;
                background-color: $color-dark;
                position: relative;
                padding: 0;

                .close {
                    position: absolute;
                    right: 0px;
                    top: 0;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 70px;
                    height: 70px;
                    border: 0;
                    border-radius: 0;
                    padding: 0;
                    margin: 0;
                    opacity: 1;
                    color: white;
                    text-shadow: none;
                    background-color: $color-dark;
                    height: 70px;
                    width: 70px;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    cursor: pointer;

                    i {
                        font-size: 24px;
                        transition: all .2s ease-in;
                        backface-visibility: hidden;
                    }

                    &:hover {
                        opacity: 1;
                        i {
                            transform: scale(1.1);
                        }
                    }
                }
            }

            &-form {
                width: calc(100% - 70px);
            }
        }
    }
}