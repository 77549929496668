#section1 {
    .container {
        padding-top: 100px;
        position: relative;
        z-index: 99;

        @include mq('phone-small', max) {
            padding-top: 75px;
        }
    }

    .section_bg {
        @extend %bgcover;
        background-image: linear-gradient(90deg, rgba(23, 25, 25, .2), rgba(23, 25, 25, .2)), url('../img/cloud.png');

        @include mq('tablet', max) {
            background-image: linear-gradient(90deg, rgba(23, 25, 25, .65), rgba(23, 25, 25, .65)), url('../img/cloud.png');
        }
    }
}

#section2 {
    .section2-top {}

    .section2-bottom {
        position: relative;
        padding-top: 100px;
        height: 100%;
        padding-left: 0;
        padding-right: 0;

        @include mq('desktop', min) {
            padding-top: 90px;
        }

        @include mq('desktop-wide', min) {
            padding-top: 175px;
        }

        @include mq('tablet', max) {
            padding-top: 30px;
        }

        @include mq('phone-small', max) {
            padding-top: 25px;
        }

        &::after {
            content: "";
            position: absolute;
            bottom: 0px;
            left: 0;
            right: 0;
            width: 100%;
            height: 30vh;
            background-color: #fff;

            @include mq('tablet', max) {
                display: none;
            }

        }
    }
}

.section-portfolio {
    &::after {
        display: none !important;
    }
}

#section3 {
    .section3-top {
        position: relative;
        padding-top: 200px;
        height: 100%;
        padding-left: 0;
        padding-right: 0;
        overflow: hidden;

        @include mq('desktop', min) {
            padding-top: 90px;
        }

        @include mq('desktop-wide', min) {
            padding-top: 175px;
        }

        @include mq('tablet', max) {
            padding-top: 75px;
        }

        @include mq('phone-small', max) {
            padding-top: 25px;
        }
    }
}

.footer {
    &__bottom {
        font-size: 14px;
        font-family: "OpenSans-Regular";
        color: rgba(255, 255, 255, 0.502);
        line-height: 2.071;

        a {
            &:hover {
                color: $color-orange;
            }
        }
    }
}

.section4-footer {
    @include mq('tablet', max) {
        height: 100%;

        .maps {
            height: 35%;
        }

        .contacts {
            height: calc(100% - 35% - 120px);
        }

        .footer__bottom {
            height: 120px;
            justify-content: center !important;
        }
    }
}