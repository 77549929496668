//Menu
.menu-icon {

    width: 54px;
    height: 54px;

    position: relative;
    z-index: 1000;
    //background-color: $menu-bg-color;

    border-radius: 50%;

    span {
        position: absolute;
        top: 50%;
        left: 50%;

        transform: translateX(-50%) translateY(-50%);

        display: block;

        width: 22px;
        height: 2px;

        background-color: $menu-color;

        &:before,
        &:after {
            content: "";

            background-color: $menu-color;

            width: 22px;
            height: 2px;

            position: absolute;
            top: 0;
            left: 0;
            transform: translateY(-6px);
            transition: transform $duration;

        }

        &:after {
            transform: translateY(6px);
        }

    }

    &:hover {
        span:before {
            transform: translateY(-8px);
        }

        span:after {
            transform: translateY(8px);
        }
    }
}

//Cross animation:
//=================================================================
.menu-icon-cross {
    background-color: $menu-bg-color;

    transition: transform $duration;

    span::before,
    span::after {
        transform-origin: 50% 50%;
    }

    span {
        transition: background $duration, transform $duration;
    }

    svg {
        fill: transparent;
        stroke-width: 2px;
        stroke: $menu-color;
        //Prep pour l'animation du cercle
        stroke-dashArray: 165;
        stroke-dashOffset: 165;

        transition: stroke-dashOffset $duration*2, fill $duration;

    }

    &.isOpen {
        transform: rotate(180deg);

        span {
            background: transparent;
        }

        span:before {
            transform: translateY(0) rotate(45deg);
        }

        span:after {
            transform: translateY(0) rotate(-45deg);
        }

        svg {
            stroke-dashOffset: 0;
        }

        &:hover {
            svg {
                fill: rgba(white, 0.1);
            }
        }
    }
}


//Path animation:
//=================================================================

.menu-icon-path {
    span {
        background-color: $menu-color;
        transition: background-color $duration steps(1, end);
    }

    svg {
        fill: $menu-bg-color;
        stroke-width: 2px;
        stroke: transparent;
        stroke-dashArray: 22 230;

        transition: stroke-dashArray $duration, stroke-dashOffset $duration, transform $duration, stroke $duration steps(1, end);
    }

    &.isOpen {

        span {
            background-color: transparent;
            transition: background-color $duration steps(1, start);
        }

        svg {
            stroke: $menu-color;
            stroke-dashOffset: -65;
            stroke-dashArray: 160;

            transition: stroke-dashArray $duration*2, stroke-dashOffset $duration, transform $duration, stroke $duration steps(1, start), fill $duration;
        }

        span:before {
            transform: translateY(0) rotate(45deg);
        }

        span:after {
            transform: translateY(0) rotate(-45deg);
        }

        &:hover {
            svg {
                fill: $menu-bg-color-lighter;
            }
        }
    }
}

//Arrow animation:
//=================================================================


.menu-icon-arrow {
    background-color: $menu-bg-color;

    transition: transform $duration;

    span,
    span:before,
    span:after {

        transition: background $duration, transform $duration, width $duration;
        transform-origin: 100% 50%;
    }

    svg {
        fill: transparent;
        stroke-width: 2px;
        stroke: $menu-color;
        //Prep pour l'animation du cercle
        stroke-dashArray: 165;
        stroke-dashOffset: 165;

        transition: stroke-dashOffset $duration*2 ease, fill $duration;

    }

    &.isOpen {
        transform: rotate(180deg);

        span:before {
            transform: translateY(0) rotate(45deg);
        }

        span:after {
            transform: translateY(0) rotate(-45deg);
        }

        span:before,
        span:after {
            width: 50%;
            right: 0;
            left: auto;
        }

        svg {
            stroke-dashOffset: 0;
        }

        &:hover {
            svg {
                fill: rgba(white, 0.1);
            }
        }
    }
}