.owl {
    &-carousel {}

    &-one {
        height: 100vh;
        color: white;
    }

    &-two {
        color: white;

        .item {
            border-top: 5px solid rgba($color: $color-blue, $alpha: .502);
            width: 170px;
            padding: 10px 0;

            cursor: pointer;

            margin: 0 auto;

            &__title {
                text-align: center;
                font-size: 13px;
                font-family: "OpenSans-Regular";
                color: rgb(255, 255, 255);
                line-height: 1.385;
                text-align: center;

            }
        }

        .owl-item.current {
            .item {
                border-top-color: $color-orange;
            }
        }
    }

    &-item {
        &-child {
            @extend %bgcover;
            height: 100vh;
            width: 100%;
            position: relative;
            z-index: 11;

            &::before {
                content: "";
                position: absolute;
                width: 100%;
                height: 100%;
                top: 0;
                right: 0;
                left: 0;
                bottom: 0;
                z-index: -1;
                background-color: $color-blue-dark;
                opacity: .65;
            }
        }

    }

    &-project {
        margin-top: 30px;
        position: relative;
        min-height: 200px;
        z-index: 1003 !important;

        @include mq('tablet', max) {
            margin-top: 30px;
        }
    }

    &-nav {
        position: absolute;
        @extend %h-c;
        top: 180px;

        &>div {
            cursor: pointer;
        }

        @include mq('tablet-wide', max) {
            top: unset;
            bottom: 75px;
        }

        @include mq('phone-small', max) {
            bottom: 10px;
        }

        &-project {
            position: absolute;
            right: 0;
            top: -65px;
            z-index: 1001;

            &>div {
                cursor: pointer;
            }

            @include mq('tablet-wide', max) {
            }

            @include mq('tablet', max) {
                top: unset;
                bottom: -75px;
                right: unset;
                left: 50%;
                transform: translate(-50%);
                // color: $color-dark;
            }

            @include mq('phone-small', max) {
                bottom: -50px;
            }
        }
    }

    &-dots {
        position: absolute;

        @extend %h-c;
        bottom: 100px;
    }
}

.second-slider {
    position: absolute;
    bottom: 70px;
    width: 75%;
    left: 50%;
    z-index: 99;
    transform: translateX(-50%);

    @include mq('tablet-wide', max) {
        display: none;
    }
}