.my-btn {
    display: block;
    
    &__yellow {
        border: 1px solid $color-orange;
        padding: 8px 20px;
        width: 150px;
        font-size: 13px;
        font-family: "OpenSans-Bold";
        color: rgb(23, 25, 25);
        text-transform: uppercase;
        text-align: center;
        background-color: $color-orange;


        i {
            @extend %tr03;
        }

        &:hover {
            color: rgb(23, 25, 25);

            i {
                transform: translateX(5px);
            }
        }
    }
    &__blue {
        border: 1px solid $color-blue;
        padding: 8px 20px;
        width: 150px;
        font-size: 13px;
        font-family: "OpenSans-Bold";
        color: white;
        text-transform: uppercase;
        text-align: center;
        background-color: $color-blue;


        i {
            @extend %tr03;
        }

        &:hover {
            color: white;

            i {
                transform: translateX(5px);
            }
        }
    }
}