.counter {
    &__count {
        font-size: 72px;
        font-family: "OpenSans-ExtraBold";
        color: rgb(232, 217, 121);
        text-shadow: 0 0 7px $color-orange;
    }

    sup {
        font-size: 36px;
        font-family: "OpenSans-ExtraBold";
        color: rgb(232, 217, 121);
        line-height: 0.722;
        top: -2rem;
        margin-left: 5px;

        text-shadow: 0 0 7px $color-orange;
    }

    &__title {
        font-size: 16px;
        font-family: "OpenSans-Regular", Arial, Helvetica, sans-serif;
        color: rgba(255, 255, 255, 0.502);
        line-height: 1.5;
        text-align: left;
    }
}

.product {
    background-color: $color-blue-darker;
    padding: 35px 25px 40px;

    @include mq('tablet-wide', max) {
        padding: 15px 15px 30px;
    }

    @include mq('tablet', max) {
        padding: 15px 8px 15px;
        border-left: 1px solid $color-blue-dark;
        border-bottom: 1px solid $color-blue-dark;
    }

    position: relative;

    &__top {
        height: 60px;
    }

    &__logo {

        @include mq('tablet', max) {
            img {
                max-width: 30px;
            }
        }
    }

    &__name {
        font-size: 16px;
        font-family: "OpenSans-Bold", Arial, Helvetica, sans-serif;
        color: rgb(255, 255, 255);

        @include mq('tablet', max) {
            font-size: 14px;
        }
    }

    &__title {
        font-size: 14px;
        font-family: "OpenSans-Regular", Arial, Helvetica, sans-serif;
        color: rgb(255, 255, 255);
        line-height: 1.5;
        text-align: left;

        @include text-clamp(4, 80px);

        @include mq('tablet', max) {
            display: none;
        }
    }

    &__link {
        position: absolute;
        bottom: 0;
        right: 0;
        background-color: $color-blue;
        width: 40px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;

        i {
            color: white;
        }

        @include mq('tablet', max) {
            display: none;
        }
    }

    @extend %tr03;

    &:hover {
        background-color: $color-blue;

        .product__link {
            background-color: $color-blue-darker;
        }
    }
}

.main__lines {
    left: 40px;
    position: fixed;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 1002;
    display: flex;
    pointer-events: none;

    @include mq('tablet-wide', max) {
        display: none;
    }

    &__item {
        border-right: 1px solid rgba(255, 255, 255, 0.1);
        width: calc(100% / 4.2);
        height: 100%;
    }
}

.project {
    &__title {
        background-color: #fff;
        padding: 10px;
        width: 60px;
        height: 250px;
        position: relative;

        span {
            position: absolute;
            text-align: center;
            line-height: 1;
            font-family: "OpenSans-Bold";
            color: rgb(23, 25, 25);
            font-size: 14px;
            display: block;
            top: 50%;
            left: -5px;
            transform: rotate(-90deg) translateY(-50%);
        }

        @include mq('tablet', max) {
            height: 300px;
        }
    }

    &__content {
        @include mq('tablet', max) {
            width: 100%;
            max-width: unset;
        }
    }

    &__img {
        @extend %bgcover;
        max-width: 280px;
        height: 190px;

        @include mq('tablet', max) {
            width: 100%;
            max-width: unset;
            height: 230px;
        }
    }

    &__subtitle {
        display: flex;
        font-size: 14px;
        font-family: "OpenSans-Regular";
        color: rgb(255, 255, 255);
        text-align: left;
        background-color: rgb(23, 25, 25);
        padding: 10px;
        height: 60px;
        max-width: 280px;

        @include mq('tablet', max) {
            width: 100%;
            max-width: unset;
            height: 70px;
        }
    }
}

.news {
    position: relative;
    color: white;

    &__img {
        width: 100%;
        min-height: 450px;
        @extend %bgcover;

        @include mq('tablet', max) {
            min-height: 350px;
        }
    }

    &__content {
        position: absolute;
        bottom: 0;
        width: 100%;
        height: 100px;
        @extend %tr03;
        display: flex;

        @include mq('tablet', max) {
            height: 100%;
        }

        &--left {
            width: 80%;
            background-color: rgba(23, 25, 25, .8);
            padding: 20px;

            display: flex;
            justify-content: center;
            align-items: flex-start;
            flex-direction: column;

            @include mq('tablet', max) {
                width: calc(100% - 100px);
                padding: 10px;
            }
        }

        &--right {
            width: 20%;
            background-color: rgb(51, 61, 144);
            padding: 20px;
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;

            @include mq('tablet', max) {
                width: 100px;
                padding: 10px;
            }
        }
    }

    &__title {
        font-size: 18px;
        font-family: "OpenSans-Bold";
        color: rgb(255, 255, 255);
        line-height: 1.5;

        @include mq('tablet', max) {
            font-size: 16px;
        }

    }

    &__subtitle {
        font-size: 14px;
        font-family: "OpenSans-Regular";
        color: rgb(255, 255, 255);
        line-height: 1.714;
        @extend %tr03;
        margin-top: 10px;
        opacity: 0;
        visibility: hidden;
        height: 0;

        @include mq('tablet', max) {
            opacity: 1;
            visibility: visible;
            @include text-clamp(4, 70px);
        }
    }

    &__date {
        font-size: 14px;
        font-family: "OpenSans-Regular";
        color: rgb(255, 255, 255);
        line-height: 1.5;
        text-align: center;
    }

    .arrow {
        opacity: 0;
        visibility: hidden;
        position: absolute;
        width: 100%;
        height: 0;
        bottom: 0px;
        text-align: center;
        @extend %h-c;
        @extend %tr03;

        font-size: 24px;

        a {
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
        }

        @include mq('tablet', max) {
            opacity: 1;
            visibility: visible;
            height: 100px;
        }
    }


    &:hover {
        .news {
            &__content {
                height: 100%;
            }

            &__subtitle {
                opacity: 1;
                visibility: visible;
                height: auto;
            }
        }

        .arrow {
            opacity: 1;
            visibility: visible;
            height: 100px;
        }
    }
}

.my-map {
    width: calc(100% - 100px);
    margin-bottom: -6px;

    @include mq('desktop-wide', min) {
        iframe {
            height: 550px !important;
        }
    }

    @include mq('tablet', max) {
        width: 100%;

        iframe {
            height: 100% !important;
        }
    }
}

.paeg {
    width: 100px;
    background-color: $color-blue;
    padding: 10px;
    position: relative;

    span {
        position: absolute;
        display: block;
        top: 40%;
        left: 10%;
        transform: rotate(-90deg) translateY(-50%);
        font-size: 60px;
        font-family: "OpenSans-Bold";
        color: rgba(255, 255, 255, 0.1);
    }

    @include mq('tablet', max) {
        display: none;
    }
}

.contacts {
    @include mq('tablet-wide', max) {
        border-top: 1px solid #000;
    }

    @include mq('tablet', max) {
        border-top: unset;
    }

    &-none {
        width: 106px;
        background-color: transparent;

        @include mq('tablet-wide', max) {
            display: none;
        }
    }

    &-info {
        width: calc(100% - 406px);
        background-color: $color-blue;

        display: flex;
        padding: 30px;
        justify-content: space-between;

        @include mq('tablet-wide', max) {
            width: calc(100% - 200px);
            padding: 20px;
        }

        @include mq('tablet', max) {
            width: 100%;

            flex-direction: column;

            &>div {
                width: 100%;
            }
        }
    }

    &-now {
        width: 300px;
        font-size: 16px;
        font-family: "OpenSans-Bold";
        color: rgb(232, 217, 121);
        line-height: 1.313;
        text-align: center;

        display: flex;
        align-items: center;
        justify-content: flex-end;
        flex-direction: column;

        padding: 30px;

        @include mq('tablet-wide', max) {
            width: 200px;
            padding: 20px;
        }

        @include mq('tablet', max) {
            display: none;
        }
    }
}

.contact {
    width: 25%;

    @include mq('tablet', max) {
        width: 100%;

        margin: 10px 0;
    }

    &:first-of-type {
        width: 40%;

        @include mq('tablet', max) {
            width: 100%;
        }
    }

    &:not(:last-of-type) {
        // border-right: 1px solid rgba($color: #fff, $alpha: .2);
    }

    &__type {
        font-size: 13px;
        font-family: "Open Sans";
        color: rgba(255, 255, 255, 0.302);
        line-height: 1.5;
    }

    &__title {
        margin-top: 5px;
        font-size: 14px;
        font-family: "OpenSans-Regular";
        color: rgb(255, 255, 255);
    }
}

.news-item,
.about-us,
.product-item,
.portfolio-item {
    &__img {
        img {
            max-width: 100%;
            height: auto;
        }

        height: 450px;
        width: 100%;
        @extend %bgcover;

        @include mq('tablet-wide', max) {
            height: 350px;
        }

        @include mq('tablet', max) {
            height: 250px;
        }
    }

    &__subtitle {
        font-size: 16px;
        font-family: "OpenSans-Regular", Arial, Helvetica, sans-serif;
        color: rgba(255, 255, 255, 0.8);
    }

    &__share {
        &>div {
            font-size: 16px;
            font-family: "OpenSans-Regular", Arial, Helvetica, sans-serif;
            color: rgba(255, 255, 255, 0.502);
        }
    }
}

.products {
    background-color: $color-white-darker;
    padding: 30px;
    @extend %tr03;

    &__img {
        width: 100%;
        height: 200px;
        @extend %bgcontain;
    }

    &__title {
        font-size: 14px;
        font-family: "OpenSans-Bold";
        color: rgb(23, 25, 25);
        line-height: 1.5;

        @include text-clamp(4, 85px);
    }

    &:hover {
        background-color: white;
    }
}

.portfolio {
    display: flex;
    flex-direction: column;

    &__number {
        font-size: 14px;
        font-family: "OpenSans-Regular";
        color: rgba(255, 255, 255, 0.502);
        text-transform: uppercase;
        margin-top: 25px;

        @include mq('tablet-wide', max) {
            margin-top: 15px;
        }
        @include mq('tablet', max) {
            display: none;
        }
    }

    &__title {
        font-size: 24px;
        font-family: "OpenSans-SemiBold";
        color: rgb(255, 255, 255);
        line-height: 1.25;
        margin: 15px 0;

        @include mq('tablet-wide', max) {
            font-size: 18px;
        }
        @include mq('tablet', max) {
            margin: 0;
        }
    }

    &__subtitle {
        font-size: 14px;
        font-family: "OpenSans-Regular";
        color: rgb(255, 255, 255);
        line-height: 1.5;
        margin: 15px 0;

        @include mq('tablet', max) {
            display: none;
        }

        @include mq('tablet', min) {
            @include text-clamp(4, 80px);
        }
        @include mq('tablet-wide', min) {
            @include text-clamp(5, 105px);
        }
    }

    &__info {
        display: flex;
        align-items: center;
        font-size: 14px;
        font-family: "OpenSans-Regular";
        color: rgba(255, 255, 255, 0.502);
        line-height: 1.375;

        margin-top: 15px;

        @include mq('tablet-wide', max) {
            margin-top: 10px;
        }
        @include mq('tablet', max) {
            order: -1;
            margin: 10px 0;
        }
    }

    &__img {
        width: 100%;
        height: 320px;
        @extend %bgcover;
        display: block;

        img {
            max-width: 100%;
            height: auto;
        }

        @include mq('tablet-wide', max) {
            height: 300px;
        }
        @include mq('tablet', max) {
            height: 250px;
        }
    }
}

.col-one {
    @include mq('tablet', max) {
        order: 1;
    }
}