.lang {
    a {
        font-size: 14px !important;
        font-family: "OpenSans-Regular", Arial, Helvetica, sans-serif;
        color: rgb(255, 255, 255);
        text-align: right;
        text-transform: capitalize !important;

        &.active {
            color: $color-orange;
        }

        &:hover {
            color: $color-orange;
        }
    }

    @include mq('tablet-wide', max) {
        display: none;
    }

    &__mobile {
        @include mq('tablet-wide', max) {
            display: flex;
            margin-top: 20px;
        }
    }
}

.social-links {
    a {
        color: rgb(255, 255, 255);

        &.active {
            color: $color-orange;
        }

        &:hover {
            color: $color-orange;
        }
    }

    @include mq('desktop', max) {}

    @include mq('tablet-wide', max) {
        display: none;
    }

    &__mobile {
        @include mq('tablet-wide', max) {
            display: flex;
            margin-top: 20px;
        }
    }
}

.search {
    position: absolute;
    right: 0;
    background-color: #fff;
    height: 70px;
    width: 70px;
    display: flex;
    align-items: center;
    justify-content: center;

    cursor: pointer;

    i {
        font-size: 18px;
        transition: all .2s ease-in;

        @include mq('phone-small', max) {
            font-size: 16px;
        }
    }

    &:hover {
        i {
            transform: scale(1.1);
            backface-visibility: hidden;
        }
    }

    @include mq('desktop', max) {
        display: none;
    }
}

.input-group {
    width: 100%;

    &__mobile {
        position: absolute;
        bottom: 0;
        z-index: 1000;
    }

    &__modal {
        @include mq('desktop', max) {
            // width: 85%;
        }
    }

    input,
    button {
        border-radius: 0 !important;
        color: $color-dark;
        font-size: 16px;
    }

    button {
        background-color: $color-blue;
        color: white;

        &:hover {
            color: white;
        }
    }
}

.breadcrumb {
    background-color: transparent;
    margin: 0;
    padding: 0;
    &-item {
        a {
            font-size: 14px;
            font-family: "OpenSans-Regular";
            color: rgb(255, 255, 255);
        }

        &.active {
            color: $color-orange;
        }
    }
}